<template>
  <b-container fluid="xs">
    <b-row align-h="center">
      <b-col cols="8"
        ><b-img
          :src="require('@/assets/AptusCare-Logo.png')"
          alt="AptusCare LLC"
          fluid
          rounded
      /></b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="12">
        <b-container class="shadow-lg p-3 mb-5 bg-white rounded">
          <b-row align-h="center">
            <b-col class="d-none d-md-block" cols="6">
              <b-img
                :src="require('@/assets/AptusCare-Login-Sidebar.png')"
                alt="AptusCare LLC"
                fluid-grow
                rounded
                center
              />
            </b-col>
            <b-col sm="12" md="4" align-self="center" class="ml-4"
              ><b-form @submit.prevent="submit">
                <b-form-row>
                  <b-form-group
                    id="login-user-group"
                    label="Email Address"
                    label-for="login-user"
                    description="Please use your email address to login."
                  >
                    <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                      <template v-slot:prepend>
                        <b-input-group-text>
                          <font-awesome-icon
                            icon="address-card"
                          ></font-awesome-icon>
                        </b-input-group-text>
                      </template>
                      <b-form-input
                        id="login-user"
                        v-model="form.email"
                        type="email"
                        required
                        placeholder="Enter Email"
                        size="lg"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-form-row>

                <b-form-row>
                  <b-form-group
                    id="login-password-group"
                    label="Password"
                    label-for="login-password"
                    description="Please enter your password"
                  >
                    <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                      <template v-slot:prepend>
                        <b-input-group-text>
                          <font-awesome-icon icon="user-secret" />
                        </b-input-group-text>
                      </template>
                      <b-form-input
                        id="login-password"
                        v-model="form.password"
                        type="password"
                        required
                        size="lg"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-form-row>

                <b-form-row>
                  <app-button type="submit" is-selected>Login</app-button>
                </b-form-row>
                <b-form-row>
                  <a
                    style="margin-top: 1rem;text-decoration:underline"
                    href="#"
                    @click="forgotPassword()"
                    >Forgot Password?</a
                  >
                </b-form-row>
                <b-form-row>
                  <span class="small mt-4">Version {{ version }}</span>
                </b-form-row>
              </b-form></b-col
            >
          </b-row>
        </b-container>
      </b-col>
    </b-row>

    <b-modal
      id="passwordModal"
      title="Request Password Reset"
      size="x"
      centered
      show-backdrop
      content-class="shadow"
      no-close-on-backdrop
      no-close-on-esc
      show-header
      hide-footer
      header-bg-variant="dark"
      header-text-variant="light"
      @shown="focusOnEmailAddress"
    >
      <b-container>
        <b-row>
          <b-col>
            <b-form @submit.prevent="submit">
              <b-form-row
                id="msg-result"
                ref="msg-result"
                :class="[{'success-msg':email_sent},{'error-msg':!email_sent}]"
                style="display:none;"
              >
                {{ result_msg }}
              </b-form-row>
              <b-form-row>
                <b-form-group
                  id="user-password-group"
                  label="Email Address"
                  label-for="login-user"
                  description="An email will be sent to this address to change password."
                >
                  <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <template v-slot:prepend>
                      <b-input-group-text>
                        <font-awesome-icon
                          icon="address-card"
                        ></font-awesome-icon>
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      id="user-email"
                      v-model="form.email"
                      type="email"
                      required
                      placeholder="Enter Email"
                      size="lg"
                      ref="passwordResetRequest"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-form-row>
              <b-form-row>
                <label style="color:red;display:none" id="error-message"
                  >Please enter email.</label
                >
              </b-form-row>
              <b-form-row>
                <b-col>
                  <app-button is-selected is-narrow @click="sendEmailPassword()">Reset</app-button>
                </b-col>
                <b-col>
                  <app-button is-selected is-narrow is-danger @click="hideForgotPassword()">Close</app-button>
                </b-col>
              </b-form-row>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <!--Modal for incorrect detals------------------------------------------------------------------------------>
    <b-modal
      id="incorrectDetails"
      title="Authentication Failed"
      size="x"
      centered
      show-backdrop
      content-class="shadow"
      no-close-on-backdrop
      no-close-on-esc
      show-header
      hide-footer
      header-bg-variant="dark"
      header-text-variant="light"
    >
      <b-container>
        <b-row>
          <b-col>
            We were unable to process your login due to incorrect authentication
            details. Please try again.
          </b-col>
        </b-row>
        <b-row align-h="end">
          <b-col>
            <app-button
              @click="hideIncorrectDetails()"
              is-danger
              is-selected
              style="float:right;"
              >Close</app-button
            >
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!---------------------------------------------------------------------------------------------------->
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { validationMixin } from "../mixins/validations.js";
import AppButton from "@/components/AppButton.vue";

export default {
  name: "login-panel",
  title: "AptusCare Login Panel",
  components: {
    AppButton,
  },

  computed: {
    ...mapGetters({
      user: "authenticated/user",
    }),
  },
  data: function() {
    return {
      form: {
        email: null,
        password: null,
        remember_me: true,
      },
      password_email: {
        email: null,
      },
      email_sent: false,
      result_msg: "",
      url: process.env.VUE_APP_API_URL,
      version: process.env.VUE_APP_VERSION,
    };
  },

  methods: {

    focusOnEmailAddress() {
      this.$refs.passwordResetRequest.focus();
    },

    submit() {
      this.$bvModal.show("loaderModal");

      this.$store.dispatch('authentication/authenticate', this.form)
          .then(() => {this.$router.push({ name: "dashboard"})})
          .catch(() => {this.$bvModal.show("incorrectDetails"); this.$bvModal.hide("loaderModal")})

      this.$bvModal.hide("loaderModal");
    },

    forgotPassword() {
      this.$bvModal.show("passwordModal");
    },

    hideForgotPassword() {
      this.form.email = null;
      this.form.password = null;
      this.$bvModal.hide("passwordModal");
    },

    hideIncorrectDetails() {
      this.form.email = null;
      this.form.password = null;
      this.$bvModal.hide("incorrectDetails");
    },

    async sendEmailPassword() {
      document.getElementById("error-message").style.display = "none";
      document.getElementById("msg-result").style.display = "none";
      var self = this;
      if (this.form.email) {
        if (
          validationMixin.methods.validateEmail(this.form.email) &&
          this.form.email.trim().length > 0
        ) {
          this.$bvModal.show("loaderModal");
          let response = await axios.post("forgotPassword", this.form);
          if (response.status == 200 && response.data.message == "email sent") {
            this.email_sent = true;
            this.result_msg = "Email successfully sent";
            document.getElementById("msg-result").style.display = "block";
            setTimeout(function() {
              self.hideForgotPassword();
            }, 5000);
          } else {
            //  output errors.
            this.email_sent = false;
            this.result_msg = response.data.message;
            document.getElementById("msg-result").style.display = "block";
          }
          this.$bvModal.hide("loaderModal");
        } else {
          document.getElementById("error-message").style.display = "block";
          document.getElementById("error-message").innerText =
            "You have entered an invalid email address!";
        }
      } else {
        document.getElementById("error-message").style.display = "block";
        document.getElementById("error-message").innerText =
          "Please enter email.";
      }
    },

    checkUser() {
      if (localStorage.getItem("token")) {
        this.$router.replace({
          name: "dashboard",
        });
      }
    },
  },
};
</script>

<style scoped>
.verticle-centered {
  margin: auto;
  width: 50%;
  padding: 10px;
}
.margin-top {
  margin-top: 5%;
}
#passwordModal .form-row {
  margin-left: 70px;
  margin-right: 70px;
}
.success-msg {
  background-color: aquamarine;
  color: #000;
  text-align: center;
}
.error-msg {
  background-color: crimson;
  color: #fff;
  text-align: center;
}

.rounded-login {
  border-radius: 5px;
  margin-right: 1rem;
}

.my-container {
  border: 1px solid black;
  margin: 3rem;
}

.my-row {
  border: 1px solid red;
}

.my-col {
  border: 1px dotted green;
}
</style>
