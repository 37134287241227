<template>
    <b-container fluid>
        <login></login>
        <loader></loader>
    </b-container>

</template>

<script>
import Login from "/src/components/LoginComponent.vue";
import loader from '/src/components/LoaderModal.vue'

export default {
    name: "login-panel",
    components: {
        Login,
        loader
    },
};
</script>

<style>
</style>
